import { PlanListPresetId } from '@wix/pricing-plans-common/blocks';
import thumbnails from '../../layout-thumbnails';

export const PLAN_LIST_PRESET_CATEGORIES = [
  { id: 'vertical', nameKey: 'blocks.plan-list.presets.vertical' },
  { id: 'horizontal', nameKey: 'blocks.plan-list.presets.horizontal' },
] as const;

interface PlanListPreset {
  id: PlanListPresetId;
  src: string;
  categoryId: (typeof PLAN_LIST_PRESET_CATEGORIES)[number]['id'];
}

export const isValidPlanListPresetId = (id: string): id is PlanListPresetId => {
  return Object.values(PlanListPresetId).includes(id as PlanListPresetId);
};

const HORIZONTAL_PLAN_LIST_PRESETS = [PlanListPresetId.horizontal, PlanListPresetId.horizontal_fb];
export const isHorizontalPlanListPreset = (id: PlanListPresetId) => {
  return HORIZONTAL_PLAN_LIST_PRESETS.includes(id);
};

export const PLAN_LIST_PRESETS: PlanListPreset[] = [
  { id: PlanListPresetId.default, categoryId: 'vertical', src: thumbnails.plan_list_vertical },
  { id: PlanListPresetId.default_fb, categoryId: 'vertical', src: thumbnails.plan_list_vertical_fb },
  { id: PlanListPresetId.default_tt_d, categoryId: 'vertical', src: thumbnails.plan_list_vertical_tt_d },
  { id: PlanListPresetId.default_fb_tt_d, categoryId: 'vertical', src: thumbnails.plan_list_vertical_fb_tt_d },
  { id: PlanListPresetId.default_no_image, categoryId: 'vertical', src: thumbnails.plan_list_vertical_no_image },
  { id: PlanListPresetId.default_fb_no_image, categoryId: 'vertical', src: thumbnails.plan_list_vertical_fb_no_image },
  { id: PlanListPresetId.horizontal, categoryId: 'horizontal', src: thumbnails.plan_list_horizontal },
  { id: PlanListPresetId.horizontal_fb, categoryId: 'horizontal', src: thumbnails.plan_list_horizontal_fb },
  { id: PlanListPresetId.horizontal_tt_d, categoryId: 'horizontal', src: thumbnails.plan_list_horizontal_tt_d },
  { id: PlanListPresetId.horizontal_fb_tt_d, categoryId: 'horizontal', src: thumbnails.plan_list_horizontal_fb_tt_d },
  { id: PlanListPresetId.horizontal_no_image, categoryId: 'horizontal', src: thumbnails.plan_list_horizontal_no_image },
  {
    id: PlanListPresetId.horizontal_fb_no_image,
    categoryId: 'horizontal',
    src: thumbnails.plan_list_horizontal_fb_no_image,
  },
];

export const MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID: { [key in PlanListPresetId]: PlanListPresetId } = {
  [PlanListPresetId.default]: PlanListPresetId.default,
  [PlanListPresetId.default_fb]: PlanListPresetId.default_fb,
  [PlanListPresetId.default_fb_no_image]: PlanListPresetId.default_fb_no_image,
  [PlanListPresetId.default_fb_tt_d]: PlanListPresetId.default_fb_tt_d,
  [PlanListPresetId.default_no_image]: PlanListPresetId.default_no_image,
  [PlanListPresetId.default_tt_d]: PlanListPresetId.default_tt_d,
  [PlanListPresetId.horizontal]: PlanListPresetId.default,
  [PlanListPresetId.horizontal_fb]: PlanListPresetId.default_fb,
  [PlanListPresetId.horizontal_fb_no_image]: PlanListPresetId.default_fb_no_image,
  [PlanListPresetId.horizontal_fb_tt_d]: PlanListPresetId.default_fb_tt_d,
  [PlanListPresetId.horizontal_no_image]: PlanListPresetId.default_no_image,
  [PlanListPresetId.horizontal_tt_d]: PlanListPresetId.default_tt_d,
  [PlanListPresetId.mobile]: PlanListPresetId.mobile,
  [PlanListPresetId.mobile_no_image]: PlanListPresetId.mobile_no_image,
  [PlanListPresetId.mobile_fb]: PlanListPresetId.mobile_fb,
};
